import React, { useMemo } from "react";
import styled from "styled-components";

import { EstimationHeaderProps, ItemState } from "@appfire/poker-core";
import { themeGet } from "@fuegokit/react";

import { JiraIssue } from "../../../types";
import { TimerControls } from "../GameControls/components/TimerControls";
import { useGameData } from "../GameProvider";
import { AdminActions } from "./AdminActions";
import { AverageScore } from "./AverageScore";
import { VotingCounter } from "./VotingCounter";

const EstimationHeaderContainer = styled.div`
  border-radius: 3px;
  background: ${themeGet("colors.background.accent.purple.subtlest")};
  width: 100%;
  margin-top: 16px;
  height: 40px;
  padding: 4px;
  display: flex;

  > div {
    flex: 1;
  }
`;

const StyledTimerControls = styled(TimerControls)`
  justify-content: flex-end;
`;

const MiddleSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export function EstimationHeader(props: Readonly<EstimationHeaderProps<JiraIssue>>) {
  const { isGameAdmin, game } = useGameData();
  const { activeItem, activeItemState, votedParticipantsCount, votingParticipantsCount, revealVotes } = props;
  const activeItemVotes = useMemo(() => {
    if (activeItem) {
      return game.voting?.[activeItem.id];
    }
  }, [activeItem, game]);

  if (!activeItem) return null;

  return (
    <EstimationHeaderContainer>
      <div>{isGameAdmin && <AdminActions {...props} />}</div>
      <MiddleSectionWrapper>
        <VotingCounter
          votedParticipantsCount={votedParticipantsCount}
          votingParticipantsCount={votingParticipantsCount}
        />
        <AverageScore activeItemVotes={activeItemVotes} activeItemState={activeItemState} />
      </MiddleSectionWrapper>
      <div>
        {activeItemState === ItemState.PERSONAL_ESTIMATION ? (
          <StyledTimerControls isGameAdmin={isGameAdmin} onTimerEnd={revealVotes} />
        ) : null}
      </div>
    </EstimationHeaderContainer>
  );
}
