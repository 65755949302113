import React from "react";

import { ChangelogEntry } from "./ChangelogEntry";

export function October2024() {
  return (
    <ChangelogEntry title="October 2024 update" date={new Date("2024-10-03")}>
      <p>We’ve added some exciting new features to Planning Poker for Jira Cloud to improve your experience:</p>
      <br />
      <h5>&#x2728; Cleaner Ticket View</h5>
      <p>Hide estimated tickets with a new toggle, keeping the focus on what still needs estimating.</p>
      <h5>&#x1F6E0; Game Cloning Made Easy</h5>
      <p>Admins and estimators are now automatically included when cloning games, saving you time.</p>
      <h5>&#x1F4CA; Average Score Display</h5>
      <p>Moderators can now instantly see the average score, speeding up final estimates.</p>
      <h5>&#x1F464; Avatars with Tooltips</h5>
      <p>Hover over avatars to quickly see who has voted during the game.</p>
      <br />
      <p>
        Try it out today and let us know what you think! For more details, please refer to the{" "}
        <a
          href="https://appfire.atlassian.net/wiki/spaces/PP/pages/1336148174/Release+notes+October+2024"
          rel="noreferrer"
          target="_blank"
        >
          release notes.
        </a>
      </p>
    </ChangelogEntry>
  );
}
