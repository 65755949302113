import React, { useMemo } from "react";

import { ItemState } from "@appfire/poker-core";
import Lozenge from "@atlaskit/lozenge";

import { Voting } from "../../../types";
interface AverageScoreProps {
  activeItemState: ItemState;
  activeItemVotes?: Voting;
}

export function AverageScore({ activeItemVotes, activeItemState }: Readonly<AverageScoreProps>) {
  const parseVote = (vote: string) => {
    if (vote === "?" || vote === "coffee") return null;

    const votePattern = /^(\d+(\.\d+)?)(h)?$/;
    const numericMatch = votePattern.exec(vote);

    if (numericMatch) {
      const numericVote = Number(numericMatch[1]);
      return !isNaN(numericVote) ? numericVote : null;
    }
    return null;
  };

  const calculateAverage = (votes: Record<string, string>) => {
    let sum = 0;
    let count = 0;

    for (const vote of Object.values(votes)) {
      const numericVote = parseVote(vote);
      if (numericVote !== null) {
        sum += numericVote;
        count++;
      }
    }

    if (count === 0) return "N/A";

    const average = sum / count;
    return average % 1 === 0 ? average : average.toFixed(2);
  };

  const averageLabel = useMemo(() => {
    if (activeItemVotes?.votes && activeItemState === ItemState.FINAL_ESTIMATION) {
      return calculateAverage(activeItemVotes.votes);
    }
    return "N/A";
  }, [activeItemVotes?.votes, activeItemState]);

  return (
    <Lozenge isBold={true} appearance="new">
      {`Average score ${averageLabel}`}
    </Lozenge>
  );
}
